<style lang="scss" scoped>
.fdd {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    flex-grow: 1;
    background: white;
    margin-top: 12px;
    border-radius: 12px;
    overflow: hidden;
  }
}
</style>

<template>
  <div class="fdd" style="height: 100%; overflow-y: auto">
    <!-- 过滤区域 -->
    <box-item class="box">
      <div class="filter-container">
        <div class="filter-input-container">
          <!-- 区域 -->
          <div class="input-item">
            <div class="input-item-title">优先级</div>
            <el-select v-model="v1">
              <el-option :value="null">查看全部</el-option>
              <el-option value="低">低</el-option>
              <el-option value="中">中</el-option>
              <el-option value="高">高</el-option>
              <el-option value="严重">严重</el-option>
            </el-select>
          </div>
          <!-- 设备 -->
          <div class="input-item">
            <div class="input-item-title">状态</div>
            <el-select v-model="v2">
              <el-option :value="null">查看全部</el-option>
              <el-option :value="0">已读</el-option>
              <el-option :value="1">新的</el-option>
              <el-option :value="2">已确认</el-option>
            </el-select>
          </div>

          <!-- 比较类型 -->
          <div class="input-item">
            <div class="input-item-title">报告期开始</div>
            <el-date-picker v-model="v3"></el-date-picker>
          </div>

          <!-- 时间刻度 -->
          <div class="input-item">
            <div class="input-item-title">报告期结束</div>
            <el-date-picker v-model="v4"></el-date-picker>
          </div>

          <!-- <el-input></el-input> -->
        </div>

        <el-button type="primary" @click="loadData">提交</el-button>
      </div>
    </box-item>

    <div class="main">
      <box-item style="height: 100%;">
        <el-table :data="tableData" stripe height="620" style="width: 100%">
          <el-table-column prop="height" label="优先级"> </el-table-column>
          <el-table-column prop="time" label="时间"> </el-table-column>
          <el-table-column prop="content" label="内容"> </el-table-column>
          <el-table-column prop="status" label="状态"> </el-table-column>
        </el-table>
      </box-item>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const v1 = ref(null);
const v2 = ref(null);
const v3 = ref(null);
const v4 = ref(null);
const tableData = ref([]);
const loadData = () => {
  let result = [
    {
      height: "严重",
      time: "2023.5.2",
      content: "高压制冷机故障",
      status: "已读",
    },
    {
      height: "严重",
      time: "2023.5.1",
      content: "高压制冷机故障",
      status: "已读",
    },
    {
      height: "严重",
      time: "2023.3.6",
      content: "高压制冷机故障",
      status: "已读",
    },
    {
      height: "高",
      time: "2023.5.16",
      content: "高压制冷机故障",
      status: "新的",
    },
    {
      height: "高",
      time: "2023.4.20",
      content: "高压制冷机故障",
      status: "新的",
    },
    {
      height: "中",
      time: "2023.1.16",
      content: "高压制冷机故障",
      status: "已确认",
    },
    {
      height: "中",
      time: "2023.2.22",
      content: "高压制冷机故障",
      status: "新的",
    },
    {
      height: "低",
      time: "2023.5.20",
      content: "高压制冷机故障",
      status: "已读",
    },
    {
      height: "低",
      time: "2023.5.14",
      content: "高压制冷机故障",
      status: "新的",
    },
    {
      height: "低",
      time: "2023.4.1",
      content: "高压制冷机故障",
      status: "已确认",
    },
  ];
  let res = [];
  // if (v1.value) {
  //   res = result.filter((item) => {
  //     if (item.height == v1.value) {
  //       return item;
  //     }
  //   });
  // } else {
  // }
  res = result;

  tableData.value = res;
};

loadData();
</script>
